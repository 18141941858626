import React from 'react';
import PropTypes from 'prop-types';
import { CustomerContext } from './CustomerContext';

export const CustomerProvider = ({ children }) => {
  const isServer = !(
    typeof window !== 'undefined'
    && window.document
    && window.document.createElement);

  const persistedCustomerInformationContext = {
    membershipInformation: null,
    THDCustomer: null,
    isExchangeCustomer: false,
    exchangeCustomerCookies: null,
  };

  /**
   * On page load, we attempt to get the values from local storage first and set context.
   *
   * If we have them, we can pass them down to the consumer (the useMembershipInformation hook).
   *
   * If we don't have them, we'll pass down the default null values and let the consumer...
   *
   * TODO: have consuming child update membershipInformation when call to Loyalty is made^
   */
  if (!isServer) {
    persistedCustomerInformationContext.membershipInformation = window.THDCustomer?.default?.membershipInformation;
    persistedCustomerInformationContext.THDCustomer = window.THDCustomer?.default;
    persistedCustomerInformationContext.isExchangeCustomer = window.THDCustomer?.default?.isExchangeCustomer;
    persistedCustomerInformationContext.exchangeCustomerCookies = window.THDCustomer?.default?.exchangeCustomerCookies;
  }

  return (
    <CustomerContext.Provider value={persistedCustomerInformationContext}>
      {children}
    </CustomerContext.Provider>
  );
};

CustomerProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

CustomerProvider.defaultProps = {
  children: null,
};