import digitalData from 'digital-data';
import {
  BUTTON_SUBMISSION,
  MY_ACCOUNT_SETTINGS,
  OVERLAY,
  STASH
} from '../constants';

export const projectDetails = () => {
  const OVERVIEW_EDIT_FORM = 'projects: overview edit form';
  const EDIT_PROJECT_DETAILS = 'projects: project details:';
  const CREATE_GROUP = 'projects: create group';
  const GROUP_MODIFICATION = 'projects: group modification';
  const MATERIAL_TAB_SELECT_ALL = 'projects: materials: select all';
  const ADD_ITEM_OVERLAY = 'projects: add items overlay';
  const ADD_ITEM_OVERLAY_CLICK_TO_PIP = 'projects: add items overlay click to PIP';
  const ADD_ITEM_WITH_NEW_GROUP = 'projects: add to project action w/new group created';
  const ADD_ITEM_IN_EXISTING_GROUP = 'projects: add to project action w/NO new Group created';
  const REMOVE_ITEM = 'projects: update remove';
  const DETAILS_COLLAB = 'projects: project details: collab';
  const PRODUCT_CLICK = 'projects: project details: product click';

  // 7.51.15 Edit Jobsite or Project info
  LIFE_CYCLE_EVENT_BUS.on(OVERVIEW_EDIT_FORM, ({ output }) => {
    const form = output?.form || '';
    const ddoEvent = {
      category: {
        primaryCategory: OVERLAY
      },
      eventInfo: {
        eventName: MY_ACCOUNT_SETTINGS,
      },
      myAccount: {
        settings: `${EDIT_PROJECT_DETAILS}>${form}`,
      }
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.16 Create a Group
  LIFE_CYCLE_EVENT_BUS.on(CREATE_GROUP, () => {
    const ddoEvent = {
      category: {
        primaryCategory: OVERLAY
      },
      eventInfo: {
        eventName: CREATE_GROUP,
      }
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.17 Create, edit, or delete a group
  LIFE_CYCLE_EVENT_BUS.on(GROUP_MODIFICATION, ({ output }) => {
    const groupAction = output?.groupAction || '';
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: MY_ACCOUNT_SETTINGS,
      },
      myAccount: {
        settings: `projects: materials: group: ${groupAction}`,
      }
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.18 Material tab select all
  LIFE_CYCLE_EVENT_BUS.on(MATERIAL_TAB_SELECT_ALL, () => {
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: MY_ACCOUNT_SETTINGS,
      },
      myAccount: {
        settings: 'projects: materials: select all',
      }
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.19 Add items overlay
  LIFE_CYCLE_EVENT_BUS.on(ADD_ITEM_OVERLAY, () => {
    const ddoEvent = {
      category: {
        primaryCategory: OVERLAY
      },
      eventInfo: {
        eventName: 'projects: add items to project',
      }
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.21 Add items overlay click to PIP
  LIFE_CYCLE_EVENT_BUS.on(ADD_ITEM_OVERLAY_CLICK_TO_PIP, () => {
    const ddoEvent = {
      category: {
        primaryCategory: STASH
      },
      eventInfo: {
        eventName: MY_ACCOUNT_SETTINGS,
      },
      eventMode: STASH,
      stash: {
        myAccount: {
          settings: 'projects: add items to project overlay: click to pip',
        }
      }
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.22 Add to Project action w/new Group created
  // return to this
  LIFE_CYCLE_EVENT_BUS.on(ADD_ITEM_WITH_NEW_GROUP, ({ output }) => {
    const {
      id, productSku, type, desc, address, zip, price
    } = output || {};
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: 'new group project add',
      },
      projectId: id,
      item: [{
        projectLocation: 'project details',
        quantity: '1',
        price: {
          basePrice: price
        },
        productInfo: {
          sku: productSku
        }
      }],
      myAccount: {
        settings: `projects: project created: type ${type}: desc ${desc}: address ${address}: zip ${zip}`,
      }
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.29 Add to Project action w/NO new Group created
  // return to this
  LIFE_CYCLE_EVENT_BUS.on(ADD_ITEM_IN_EXISTING_GROUP, ({ output }) => {
    const {
      id, productSku, type, desc, address, zip, price
    } = output || {};
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: 'project add',
      },
      projectId: id,
      item: [{
        projectLocation: 'project details',
        quantity: '1',
        price: {
          basePrice: price
        },
        productInfo: {
          sku: productSku
        }
      }],
      myAccount: {
        settings: `projects: project created: type ${type}: desc ${desc}: address ${address}: zip ${zip}`,
      }
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.23 Projects Update – Remove
  LIFE_CYCLE_EVENT_BUS.on(REMOVE_ITEM, ({ output }) => {
    const { id, itemsRemoved } = output || {};
    let items = [];
    // eslint-disable-next-line array-callback-return
    itemsRemoved.map((item, index) => {
      items.push({
        quantity: item.quantity,
        price: {
          basePrice: item.original
        },
        productInfo: {
          sku: item.sku
        }
      });
    });
    const ddoEvent = {
      category: {
        primaryCategory: 'project view modification'
      },
      eventInfo: {
        eventName: 'project remove',
      },
      projectId: id,
      item: items
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.37 Collaborator View Project Details Actions
  LIFE_CYCLE_EVENT_BUS.on(DETAILS_COLLAB, ({ output }) => {
    const action = output?.action || '';
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION,
      },
      eventInfo: {
        eventName: MY_ACCOUNT_SETTINGS,
      },
      myAccount: {
        settings: `${DETAILS_COLLAB}: ${action}`,
      }
    };
    digitalData.event.push(ddoEvent);
  });

  // 7.51.38 Project Details: Click to PIP
  LIFE_CYCLE_EVENT_BUS.on(PRODUCT_CLICK, () => {
    const ddoEvent = {
      category: {
        primaryCategory: STASH
      },
      eventInfo: {
        eventName: MY_ACCOUNT_SETTINGS,
      },
      eventMode: STASH,
      stash: {
        myAccount: {
          settings: PRODUCT_CLICK
        }
      }
    };
    digitalData.event.push(ddoEvent);
  });
};
