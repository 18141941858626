// ANALYTICS COMMON
export const NUCLEUS = 'nucleus';
export const STASH = 'stash';
export const MODULE_CLICK = 'content module click';
export const CLICKTHROUGH = 'clickthrough';
export const BUTTON_SUBMISSION = 'button submission';
export const INTERACTION = 'interaction';
export const SYSTEM = 'system';
export const ATC = 'atc';
export const PAGE_AB_TEST = 'hd home v1';
export const SEARCH_PAGE_AB_TEST = 'plp:version:gen2';
export const DEFAULT_BROWSE_CATEGORY_AB_TEST_L1 = 'category:l1:version:gen2';
export const DEFAULT_BROWSE_CATEGORY_AB_TEST_L2 = 'category:l2:version:gen2';
export const DUAL_PATH_AB_TEST = 'dual path';
export const PAGE_DEFAULT_TEMPLATE = 'default';
export const INSPIRATIONAL = 'inspirational';
export const PRODUCTS_AVAILABILITY = 'all products';
export const PLP_GRID = 'grid';
export const NO_FILTER = 'no filter';
export const PRODUCT_POD = 'product pod';
export const PRODUCT_VIEW = 'product view';
export const ONLINE_STORE = '8119';
export const CUSTOM_CATALOG = 'custom catalog';
export const NOT_CUSTOM_CATALOG = 'not custom catalog';
export const MAJOR_APPLIANCE = 'major-appliances';
export const B2B_MAJOR_APPLIANCE = 'b2b-major-appliances';
export const TABLE = 'Table';
export const TABLEVIEW = 'tableview';
export const MODULE = 'module';
export const RECS_CART_ADDITION_INFO = 'recommendation cart addition';
export const STRATEGY = 'project-based';
export const SECTION = 'dyn prod rec';
export const RECOMMENDATION_VERSION = 'v1';
export const CTL_RECOMMENDATION_VERSION = 'v1';
export const CTL_STRATEGY = 'ctl-decor';
export const RECOMMENDATION_EVENTNAME = 'recommendation pip load';
export const RECENTLY_VIEWED_EVENTNAME = 'recently viewed pip load';
export const SPONSORED_RECOMMENDATION_PIP_LOAD = 'sponsored recommendation pip load';
export const SP = 'sp';
export const HOMEPAGE_SPONSORED = 'hp_sponsored';
export const INSTANT_RECS_SCHEMA = 'instantrecomm';
export const INSTANT_RECOMMENDATION = 'Instant Rec Click';
export const INSTANT_CHECKOUT = 'instant checkout';
export const AUTOMOTIVE = 'automotive';
export const OVERLAY = 'overlay';
export const CLICK = 'click';
export const LIKE = 'like';
export const DISLIKE = 'dislike';
export const STANDARD = 'standard';
export const TRENDING = 'trending';
export const MY_ACCOUNT = 'my account';
export const MY_ACCOUNT_SETTINGS = 'my account settings';
export const ACCOUNT = 'account';
export const ERROR = 'error';
export const POST_PAGE_ERROR = 'post page error';

// For Search Pages ie /s
export const SEARCH = 'search';
export const SEARCH_RESULTS = 'search results';
export const SEARCH_SORTBY = 'best match';

// For Browse Pages ie /b
export const BROWSE = 'browse';
export const BROWSE_PAGE_PAGINATION = 'pagination';
export const BROWSE_PLP_MODULE = 'plp module';
export const BROWSE_SORTBY = 'top sellers';
export const BROWSE_RESULTS = 'browse results';

// For Collection Lists
export const COLLECTION_PAGETYPE_LIST_VIEW = 'collections:list view';
export const COLLECTION_PAGETYPE_INTEGRATED_BRANDS = 'collections:integrated';
export const COLLECTION_PAGETYPE_SPACE = 'collections:space';

// For decor pages
export const SUBCONTENTDECOR = 'subcontent_decor';
export const SIZE_GUIDE = 'size guide';
export const DROPDOWN = 'dropdown';
export const PIP = 'pip';
export const NOT_APPLICABLE = 'n/a';
export const NON_BUYABLE_REQUEST_A_QUOTE_PAGE_TYPE = 'tool:leadgen:pip';

// For Rental Pages
export const RENT_ONLINE_PIP_PAGE = 'tool:productrental:pip';
export const RENT_ONLINE_RESERVATION_PAGE = 'tool:productrental:reservation';

// For PIP Pages
export const PIP_SUB_CONTENT = 'pip sub-content';
export const SHARING = 'sharing';
export const TARGETED_MERCHANDISE = 'targeted merchandise';
export const LOCAL = 'local';
export const CONTENT = 'content';
export const MAGIC_PIP = 'magic pip';
export const BIP_PAGE = 'bip';
export const SHOPPABLE_IMAGES_DRAWER = 'shoppable images drawer';

// For Compare Pages
export const COMPARE = 'compare';
export const PRODUCT_COMPARISON = 'product comparison';

// For Gift Card Page
export const GIFT_CARD = 'gift card';
export const GIFT_CARD_PAGE_TYPE = 'gift card:pip';
export const GIFT_CARD_TYPE = 'pip';
export const GIFT_CARD_EVENT = 'gift card view';
export const GIFT_CARD_EVENT_MALL = 'gift card mall';
export const GIFT_CARD_MALL_FIRST = 'first party';
export const GIFT_CARD_MALL_SPECIALITY = 'specialty gift cards';
export const GIFT_CARD_BALANCE = 'check gift card balance';
export const GIFT_CARD_BALANCE_TYPE = 'gift card: check balance';
export const GIFT_CARD_BALANCE_EVENT = 'check balance';

// For Stylequiz
export const STYLEQUIZ_PAGETYPE_COLLECTION = 'collections:style quiz';
export const STYLEQUIZ_PRIMARY_CATEGORY = 'style quiz';

// For Exercise My Privacy Rights Page
export const EXERCISE_MY_PRIVACY_RIGHTS = 'exercise privacy rights';
export const MY_PREFERENCE_CENTER = 'my preference center';

// For Questions and Answers
export const QUESTIONS_AND_ANSWERS = 'questions and answers';

// Cart Pages
export const DD_PAGE_NAME = 'shopping cart';
export const DD_SITE_NAME = 'checkout';
export const DD_PAGE_EVENT = 'cart view';
export const EMPTY_CART = 'empty cart';

// Projects Pages
export const PROJECTS_SUMMARY = 'account>projects';
export const PROJECTS_DETAILS = 'account>projects>project details';
export const PROJECTS_DETAILS_OVERVIEW = 'account>projects>project details>overview';
export const PROJECTS_DETAILS_MATERIALS = 'account>projects>project details>materials';
export const PROJECTS_SUMMARY_PAGE_TYPE = 'maml: projects';
export const PROJECTS_ENTRY_POINT = 'projects: entry point';
export const CREATE_GROUP = 'projects: create group';

// Pages
export const STOREFINDER = 'storefinder';
export const COLLECTION = 'collection';
export const CATEGORY_PAGE = 'categorypage';
export const PEP = 'pep';
export const PLP = 'plp';
export const PACKAGES_PIP = 'ppip';
export const REBATE_CENTER = 'rebate center';
export const STORE_FINDER = 'store finder';
export const ARTICLE_EXPERIENCE = 'article';
export const ARTICLELIST_EXPERIENCE = 'alp';
export const OPT_OUT = 'opt out';
export const MY_HOMEPAGE = 'homepage';
export const MY_LIST_SUMMARY = 'list summary';
export const MY_LIST_DETAILS = 'list details';
export const MY_LIST_SHARED = 'list details>shared';
export const MY_LIST_DETAILS_PAGE_TYPE = 'maml: list details';
export const COMMUNICATION_PREFERENCES = 'account>communication preferences';
export const MY_PREFERENCE_CENTER_PAGE = 'maml: my preference center';
export const BUYITAGAIN = 'buy it again page';
export const CART = 'cart';
export const GIFT_CARDS = 'giftcard';
export const PO_JOB_NAMES = 'po job names';
export const PAINT_PURCHASE_HISTORY = 'paint purchase history';
export const MILITARY_DISCOUNT = 'military discount';
export const FLOORING_PROJECTS = 'flooring projects';
export const KITCHEN_VISUALIZER = 'kitchen visualizer';

// Event Names
export const ARTICLE_LIST_PAGE = 'articlelistpage';
export const PAGINATION = 'pagination';
export const SORT = 'sort';
export const FILTER = 'filter';
export const PRIMARY_FILTER = 'primary_filter';
export const NO_EVENT = 'no_event';
export const SSKU = 'super_sku';
export const CHECK_AVAILABILITY = 'check fulfillment availability';
export const CART_ADD = 'cart add';
export const CART_ADD_LOCATION = 'cart add location';
export const CART_ADDITION_INFO = 'cart addition info';
export const CART_ADD_RECS = 'recommendation cart addition';
export const ADD_TO_CART_CLICK = 'add to cart click';
export const UGC_CLICK = 'ugc click';
export const CATEGORY_RECOMMENDATION = 'category recommendation';
export const ARTICLE_RECOMMENDATIONS = 'article recommendations';
export const SPONSORED_RECOMMENDATION_CART_ADDITION = 'sponsored recommendation cart addition';
export const SPONSORED_PROD_REC = 'sponsored prod rec';
export const GEN_MERCH_BUNDLE = 'gen merch bundle';
export const GEN_MERCH_PACKAGE = 'gen merch package';
export const BOGO_DRAWER_CLICK = 'bogo drawer';

// Business As Usual Badges
export const BADGE_MODELS_NEW = 'New';
export const BADGE_MODELS_SEASONAL = 'New This Season';
export const BADGE_MODELS_EXCLUSIVE = 'Exclusive';
export const BADGE_MODELS_TRENDING = 'Trending';
export const BADGE_MODELS_BEST_SELLER = 'Best Seller';
export const BADGE_MODELS_CUSTOM = 'Custom';
export const BADGE_MODELS_GUARANTEED = 'Guaranteed';
export const BADGE_MODELS_POPULAR = 'Popular on Pinterest';
export const BADGE_MODELS_CLEARANCE = 'in-store clearance messaging';

// eslint-disable-next-line
export const PAGE_LOAD_ERROR_MESSAGE = 'We\'re fixing it. Your search has found a page that\'s not responding. We\'ll have this patched up as soon as possible.';

// experience identifier
export const NON_BUYABLE_REQUEST_A_QUOTE = 'non-buyable-service-request-a-quote';
export const NON_BUYABLE_SERVICES = 'non-buyable-services';
export const RENT_ONLINE = 'rent-online';

// domain lists
export const DEFAULT_PRODUCT_DOMAINS = ['badges', 'identifiers', 'fulfillment', 'taxonomy', 'pricing', 'subscription'];
export const APPLIANCE_PRODUCT_DOMAINS = ['badges', 'identifiers', 'fulfillment', 'taxonomy', 'pricing'];
export const RENTAL_PRODUCT_DOMAINS = ['identifiers', 'details', 'media', 'taxonomy'];
export const RENTAL_RESERVATION_DOMAINS = ['identifiers', 'media', 'taxonomy'];
export const NON_BUYABLE_SERVICES_PRODUCT_DOMAINS = ['identifiers', 'details', 'media', 'taxonomy'];

// schema lists
export const RECS_SCHEMA_LIST = ['DynamicRecsComponent[0]', 'CategoryCarousel[0]', 'UserDataServices[0]',
  'SearchViewedRecs[0]', 'RecentlyViewed[0]', 'RecommendationsProductPodCarousel[0]',
  'RecommendationsProductPodCarousel[1]', 'CompleteTheLookProductPodCarousel[0]',
  'PlpRecsRenderPlaceholder[4]', 'InstantRecs[0]',
  'InstantRecsContainer[0]', 'ReorderRecommendations[0]', 'TodaysRecommendations[0]', 'SupplementalRecs[0]'];

// For AddToQuote
export const ADD_TO_QUOTE = 'add to quote';
export const CREATE_A_QUOTE = 'add to quote create a quote';
export const QUOTE_ADD = 'quote add';
export const NEW_QUOTE_ADD = 'new quote add';

// For BuyItAgain
export const BIA_MY_ACCOUNT = MY_ACCOUNT_SETTINGS;
export const BIA_FILTER_BY = 'buy it again: filter by ';
export const BIA_SEARCH_BUTTON = 'buy it again: search button';
export const BIA = 'buy it again';
export const BIA_PAGE_TYPE = 'maml:buy it again';

// component path identifiers
export const SPECIAL_BUY_BANNER = 'SpecialBuyBanner\\[';
export const ITEM_RELATED_GROUPS = 'itemrelatedgroups\\[';
export const CURATION = 'curation\\[';
export const VISUAL_RECS = 'visualrecommendations\\[';
export const SHOP_BY_ROOM_COLLECTION = 'shopbyroomcollection';
export const RECOMMENDATIONS = 'recommendationsproductpodcarousel\\[';
export const INSTANT_RECOMMENDATIONS_GRID = 'instantrecs\\[';
export const SEARCH_RECOMMENDATION = 'PlpRecsRenderPlaceholder\\[';
export const CTL_RECOMMENDATIONS = 'completethelookproductpodcarousel\\[';
export const MORE_IN_THIS_COLLECTION = 'product-section-collections';
export const SUPPLEMENTAL_RECOMMENDATIONS = 'SupplementalRecs\\[';
export const SWIPER_SLIDE = 'SwiperSlide\\[';
export const BUY_IT_AGAIN = 'ReorderRecommendations\\[';
export const TODAYS_RECS = 'TodaysRecommendations\\[';
export const PRICE_DROP = 'PriceDrop\\[';

// path to pro network
export const PATH_TO_PRO = 'path to pro';
export const PATH_TO_PRO_NETWORK = 'path to pro network';
export const PATH_TO_PRO_NETWORK_UNAVAILABLE = 'path to pro network>unavailable';
export const PTP_DASHBOARD_WIDGET_ID = 'ptpdashboard';
export const PTP_JOB_APPLICANTS_WIDGET_ID = 'job applicants';
export const PTP_JOB_POSTING_ACTIONS_WIDGET_ID = 'job posting actions';
export const PTP_PROFILE_SEARCH_WIDGET_ID = 'profile search';
export const PTP_LANDING_PAGE_WIDGET_ID = 'landing page';
export const PTP_PROFILE_WIDGET_ID = 'profile';
export const PTP_PROFILE_OVERLAY_WIDGET_ID = 'profile overlay';
export const PTP_HIRING_WIDGET_ID = 'hiring';
export const PATH_TO_PRO_MY_DASHBOARD = 'my dashboard';
export const PATH_TO_PRO_PROFILE_SEARCH = 'profile search';
export const PATH_TO_PRO_CANDIDATE_SEARCH = 'candidate search';
export const PATH_TO_PRO_CANDIDATE_PAGE = 'candidate page';
export const PATH_TO_PRO_JOB_POSTINGS = 'job postings';
export const PATH_TO_PRO_JOB_POSTING_DETAILS = 'job posting details';
export const PATH_TO_PRO_JOB_APPLICANTS = 'job applicants';
export const SUBCONTENT_PTP = 'subcontent_ptp';
export const PTP_COMPONENT = 'ptp';
export const ACTION = 'action';

// self-service
export const SELF_SERVICE = 'self service';
export const SELF_SERVICE_PROPANE = 'self service>propane tanks';
export const EMPTY = '';
export const LIST = 'list';

// quick-view

export const MERCHANDISE = 'MERCHANDISE';
export const MAJOR_APPLIANCES_UPPERCASE = 'MAJOR_APPLIANCE';

// Product-pod parent names
export const IRG = 'irg';
export const RECENTLY_VIEWED = 'recently-viewed';
export const SPONSORED_CAROUSEL = 'sponsored-carousel';
export const RECS_PRODUCT_POD_CAROUSEL = 'recs-product-pod-carousel';
export const RECS_DTS_PRODUCT_POD_CAROUSEL = 'recs-dts-product-pod-carousel';
export const INSTANT_RECS = 'instant-recs';
export const PLP_RECS_PLACEHOLDER = 'plp-recs-placeholder';
export const SUPPLEMENTAL_RECS = 'supplemental-recs';
export const FBT_CAROUSEL = 'fbt-carousel';
export const APL_BUNDLE = 'appliance-bundle';
export const VISUAL_RECOMMENDATION = 'visual-recommendation';
export const SBR_COLLECTION = 'sbr-collection';
export const IBT_BATH_COLLECTION = 'ibt bath collection';
export const SPECIAL_BUY_BANNER_CAROUSEL = 'special-buy-banner';
export const CURATION_CAROUSEL = 'curation';
export const MORE_IN_THIS_COLLECTION_SECTION = 'more-in-this-collection';
export const PROJECT_SHOPPING = 'project-shopping';
export const DISCOVERY_ZONES = 'discovery-zones';
export const DISCOVERY_ZONES_BUYITAGAIN = 'buyitagain';
export const FEATURE_BASED_RECOMMENDATIONS = 'feature-based-recommendations';

// order-sample
export const ORDER_SAMPLES = 'order samples';

// bath landing page names
export const BDS = 'bathroom design studio';
export const BDS_PAGE_TYPE = 'bathroom design studio:contents';

// parts-and-services
export const PARTS_AND_SERVICES_DRAWER = 'parts and services drawer';
export const HDPP_CART_ADD = 'hdpp cart add';
export const APPL = 'appl';

// quote-details page names

export const DELIVERY_ZIP_UPDATE_EVENT = 'quote details page: updated zip code';
export const DELIVERY_ZIP_OVERLAY_EVENT = 'quote details: enter delivery zip code';

//  Dashboard
export const ACCOUNT_DASHBOARD_CLICK = 'account dashboard click';

// Fit Compatibility
export const FIT_COMPATIBILITY_STEP4 = 'fit compatibility: step 4 decide on depth';
export const FIT_COMPATIBILITY_DRAWER = 'fit compatibility: appl make sure it fits';
